


















































import Vue from "vue";
import Requester from "@/requester";
import _ from "lodash";

import CustomFormActions from "../CustomFormActions.vue";
import { UserApproveFormData } from "../../../../back/src/types/formData";
import { User } from "../../../../back/src/models/User";
import { Laboratorio } from "../../../../back/src/models/Laboratorio";
import { Papel } from "../../../../back/src/models/Papel";
import { rulesRequired } from "@/utils/rules";
export default Vue.extend({
  name: "UsersFormAprovarCadastro",
  props: {
    usuario: {
      type: Object as () => User,
      default: null,
    },
    laboratorios: {
      type: Array as () => Laboratorio[],
      default: null,
    },
    papeis: {
      type: Array as () => Papel[],
      default: null,
    },
  },
  components: {
    CustomFormActions,
  },
  data() {
    return {
      loading: false,
      form: {
        userId: null,
        isAdmin: null,
        laboratorioId: null,
        papelId: null,
      },
      rules: {
        required: rulesRequired,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.preencheForm();
  },
  methods: {
    preencheForm() {
      if (this.usuario) {
        this.form.userId = this.usuario.id;
        this.form.isAdmin = this.usuario.is_admin;
        this.form.papelId = this.usuario.papel_id;
        this.form.laboratorioId = this.usuario.laboratorio_id;
      } else {
        this.form.userId = null;
        this.form.isAdmin = null;
        this.form.papelId = null;
        this.form.laboratorioId = null;
      }
    },
    submitAprovarDebounce: _.debounce(async function () {
      await this.submitAprovarForm();
    }, 200),
    async submitAprovarForm() {
      if (!this.$refs.formAprovaCadastro.validate()) {
        return;
      }
      const formData: UserApproveFormData = {
        userId: this.form.userId,
        isAdmin: this.form.isAdmin,
        papelId: this.form.papelId,
        laboratorioId: this.form.laboratorioId,
      };
      this.loading = true;
      try {
        const userAprovado = await Requester.users.private.aprovarCadastro(
          this.form.userId,
          formData,
        );
        this.$emit("usuario-aprovado", userAprovado);
        this.$root.$emit("showToast", {
          text: "Usuário aprovado com sucesso!",
          color: "success",
        });
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    usuario() {
      this.preencheForm();
    },
    "form.isAdmin": {
      handler() {
        if (this.form.isAdmin) {
          this.form.laboratorioId = null;
          this.form.papelId = null;
        } else if (this.usuario) {
          this.form.papelId = this.usuario.papel_id;
          this.form.laboratorioId = this.usuario.laboratorio_id;
        }
      },
    },
  },
});














































































































































import Vue from "vue";
import Requester from "@/requester";
import _ from "lodash";
import { User } from "../../../../back/src/models/User";
import UsersFormAlterar from "../../components/users/UsersFormAlterar.vue";
import UsersFormAprovarCadastro from "../../components/users/UsersFormAprovarCadastro.vue";
import CustomDialog from "../../components/CustomDialog.vue";
export default Vue.extend({
  name: "AdminUsuarios",
  components: {
    UsersFormAlterar,
    UsersFormAprovarCadastro,
    CustomDialog,
  },
  data() {
    return {
      loading: false,
      headerPendentes: [
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "E-mail",
          value: "email",
        },
        {
          text: "Laboratório",
          value: "laboratorio.nome",
        },
        {
          text: "Perfil",
          value: "papel.papel",
        },
        {
          text: "Aprovar",
          value: "aprovar",
          align: "center",
        },
      ],
      headerAceitos: [
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "E-mail",
          value: "email",
        },
        {
          text: "Laboratório",
          value: "laboratorio.nome",
        },
        {
          text: "Perfil",
          value: "papel.papel",
        },

        {
          text: "Status",
          align: "center",
          value: "status",
        },
        {
          text: "Alterar",
          align: "center",
          value: "alterar",
        },
      ],
      usuarios: [],
      laboratorios: [],
      papeis: [],
      tab: null,
      searchPendentes: null,
      searchAceitos: null,
      usuarioSelecionado: null,
      dialog: {
        aprovar: false,
        alterar: false,
      },
      aprovaFormDate: {
        userId: null,
        nome: null,
        email: null,
        laboratorio: {},
        papel: {},
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    usuariosPendentes() {
      return this.usuarios.filter((el: User) => !el.aceito);
    },
    usuariosAceitos() {
      return this.usuarios.filter((el: User) => !!el.aceito);
    },
  },
  mounted() {
    this.carregaDados();
  },
  methods: {
    abreDialogAprovar(usuario: User) {
      this.usuarioSelecionado = usuario;
      this.dialog.aprovar = true;
    },
    fechaDialogAprovar() {
      this.dialog.aprovar = false;
      this.usuarioSelecionado = null;
    },
    abreDialogAlterar(usuario: User) {
      this.usuarioSelecionado = usuario;
      this.dialog.alterar = true;
    },
    fechaDialogAlterar() {
      this.dialog.alterar = false;
      this.usuarioSelecionado = null;
    },
    async carregaDados() {
      this.loading = true;
      try {
        this.usuarios = await Requester.users.private.getUsers();
        this.laboratorios = await Requester.laboratorio.public.recuperaLaboratorios();
        this.papeis = await Requester.users.public.buscaPepeis();
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    usuarioModificado(usuario: User) {
      this.usuarioSelecionado = null;
      this.dialog.alterar = false;
      this.dialog.aprovar = false;
      const index = _.findIndex(
        this.usuarios,
        (el: User) => el.id === usuario.id,
      );
      if (index >= 0) {
        this.usuarios.splice(index, 1, usuario);
      }
    },
  },
});





















































import Vue from "vue";
import _ from "lodash";
import Requester from "@/requester";

import CustomFormActions from "../CustomFormActions.vue";
import { rulesRequired } from "@/utils/rules";
import { User } from "../../../../back/src/models/User";
import { Laboratorio } from "../../../../back/src/models/Laboratorio";
import { Papel } from "../../../../back/src/models/Papel";
export default Vue.extend({
  name: "UsersFormAlterar",
  props: {
    usuario: {
      type: Object as () => User,
      default: null,
    },
    laboratorios: {
      type: Array as () => Laboratorio[],
      default: null,
    },
    papeis: {
      type: Array as () => Papel[],
      default: null,
    },
  },
  components: {
    CustomFormActions,
  },
  data() {
    return {
      status: [
        {
          text: "Ativo",
          value: true,
        },
        {
          text: "Inativo",
          value: false,
        },
      ],
      form: {
        id: null,
        nome: null,
        email: null,
        laboratorio_id: null,
        papel_id: null,
        ativo: null,
      },
      rules: {
        required: rulesRequired,
      },
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.preencheForm();
  },
  methods: {
    preencheForm() {
      if (this.usuario) {
        this.form.id = this.usuario.id;
        this.form.nome = this.usuario.nome;
        this.form.email = this.usuario.email;
        this.form.laboratorio_id = this.usuario.laboratorio_id;
        this.form.papel_id = this.usuario.papel_id;
        this.form.ativo = this.usuario.ativo;
      } else {
        this.form.id = null;
        this.form.nome = null;
        this.form.email = null;
        this.form.laboratorio_id = null;
        this.form.papel_id = null;
        this.form.ativo = null;
      }
    },
    submitDebounce: _.debounce(async function () {
      if (!this.$refs.formUserAlterar.validate()) {
        return;
      }
      this.loading = true;
      try {
        const userAlterado = await Requester.users.private.editUser(
          this.usuario.id,
          {
            id: this.form.id,
            nome: this.form.nome,
            email: this.form.email,
            laboratorio_id: this.form.laboratorio_id,
            papel_id: this.form.papel_id,
            ativo: this.form.ativo,
          },
        );
        this.$root.$emit("showToast", {
          text: "Usuário alterado com sucesso",
          color: "success",
        });
        this.$emit("usuario-alterado", userAlterado);
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    }, 200),
  },
  watch: {
    usuario() {
      this.preencheForm();
    },
  },
});
